import httpClient  from '../api'; 
const API_CONTROLLER = 'area/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                name: search.name,
            }
        });
    },

    getAreas(search) { 
        let url = API_CONTROLLER + 'get-areas';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                name: search.name,
            }
        });
    },

    getAreaForFuel(data) { 
        let url = API_CONTROLLER + 'get-area-for-fuel';
        return httpClient.post(url, data);
    },
}