<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                   <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.areaCode.$error }">
                                <label for="areaCode">Mã tuyến đường</label>
                                <md-input name="areaCode" v-model="entity.areaCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.areaCode.required">Vui lòng nhập mã</span>
                            </md-field>
                            <md-field :class="{'md-invalid': submitted && $v.entity.areaName.$error }">
                                <label for="areaName">Tên tuyến đường</label>
                                <md-input name="areaName" v-model="entity.areaName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.areaName.required">Vui lòng nhập tuyến đường</span>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="startName" @md-selected="getStartSelected" :md-options="startList" @md-changed="getStarts"  @md-opened="getStarts" :class="{'md-invalid': submitted && $v.entity.startId.$error }">
                                    <label>Từ điểm</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.startId.required">Vui lòng chọn điểm bắt đầu</span>
                                </md-autocomplete>
                                <md-button @click="openStart()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="endName" @md-selected="getEndSelected" :md-options="endList" @md-changed="getEnds"  @md-opened="getEnds" :class="{'md-invalid': submitted && $v.entity.endId.$error }">
                                    <label>Tới điểm</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.endId.required">Vui lòng chọn tới điểm</span>
                                </md-autocomplete>
                                <md-button @click="openEnd()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.km.$error }">
                                <label for="km">Chiều dài(km)</label>
                                <md-input name="km" v-model="entity.km" ></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.km.required">Vui lòng nhập chiều dài</span>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.fuel.$error }"> 
                                <label for="fuel">Định mức dầu</label>
                                <md-input name="fuel" v-model="entity.fuel" ></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.fuel.required">Vui lòng nhập định mức dầu</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <startLocationList ref="startLocationList" @close="closeStart"/>
        <endLocationList ref="endLocationList" @close="closeEnd"/>
   </div>
</template>
<script>
    import areaService from './../../api/areaService';
    import locationService from './../../api/locationService';
    import messageBox from './../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from './../../mixins';
    import startLocationList from './../../components/popup/_LocationList.vue';
    import endLocationList from './../../components/popup/_LocationList.vue';

    export default ({
        components: {
            startLocationList,
            endLocationList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật tuyến đường'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                typeName: '',
                types: [],
                loadding: false,
                group: common.groupCode.area,
                entity: { id: 0, startId: 0, endId: 0, areaCode: '', areaName: '', km: 0, fuel: 0 },
                startName: '',
                startList: [],
                endName: '',
                endList: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật tuyến đường';
               this.getById();
            }
            else{
               this.title = 'Thêm mới tuyến đường';
               this.entity.areaLocations.push(this.areaLocation);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Start
            closeStart(item){
                this.startName = item.locationName;
                this.entity.startId = item.id;
                this.$refs.startLocationList.close();
            },

            getStartSelected(val){
                this.entity.startId = val.id;
                this.startName = val.locationName;
            },

            openStart(){
                this.$refs.startLocationList.open();
            },

            getStarts(searchTerm){
                const search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.startList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //End Location
            closeEnd(item){
                this.endName = item.locationName;
                this.entity.endId = item.id;
                this.$refs.endLocationList.close();
            },

            getEndSelected(val){
                this.entity.endId = val.id;
                this.endName = val.locationName;
            },

            openEnd(){
                this.$refs.endLocationList.open();
            },

            getEnds(searchTerm){
                const search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.endList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                areaService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                areaService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/area');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                areaService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.startName = response.data.start.locationName;
                        this.endName = response.data.end.locationName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/area');
            }
        },
        watch: { 
            startName: function (val) { 
                if(val == ''){
                    this.entity.startId = 0;
                }
            },
            endName: function (val) { 
                if(val == ''){
                    this.entity.endId = 0;
                }
            },
        },
        validations: {
            entity: {
                startId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                endId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                areaCode: { required },
                areaName: { required },
                km : { required },
                fuel: { required },
            }
        }
     })

</script>
